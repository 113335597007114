import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import SEO from '../components/seo'

const GutHealth = () => {
  const data = useStaticQuery(graphql`
    query {
      gutHealth: file(relativePath: { eq: "gutHealth.PNG" }) {
        childImageSharp {
          fixed(width: 320) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Fitness and Spirit- Gut Health" />
      <div className="container-main">
        <div className="container-main_div">
          <Img
            alt="Fitness and Spirit"
            fixed={data.gutHealth.childImageSharp.fixed}
            style={{
              margin: '40px auto',
              textAlign: 'center',
              display: 'block',
            }}
          />
          <h1>Gut Health</h1>
          <p>
            Did you know that you actually have more neurotransmitters in your
            stomach than you do in your brain? Psychology Today calls our
            stomach our “second brain.” I know, it is mind blowing!!!
          </p>
          <p>
            We have something called a gut-brain axis. It is a bidirectional
            communication network that links our enteric and central nervous
            system. This network is not only anatomical, but it extends to
            include endocrine, humoral, metabolic, and immune routes of
            communication as well. Our autonomic nervous system,
            hypothalamic-pituitary-adrenal (HPA) axis, and nerves within the
            gastrointestinal tract, all link the gut and the brain, allowing the
            brain to influence intestinal activities, including activity of
            functional immune effector cells; and the gut to influence mood,
            cognition, and mental health. (Jeremy Appleton, ND)
          </p>
          <p>
            So, with that being said...I have had my share of gastrointestinal,
            cognitive, mood, mental health, and immune disruptions! Through my
            experiences and research I came across an enlightening book. I
            usually do not use the word diet in my vocabulary. I prefer to use
            healthy eating and choosing it as a lifestyle. However, I am hopeful
            you will gain wisdom from the following book, The Slow Down Diet By:
            Marc David.
          </p>
          <p>
            You can achieve more by doing less...Your metabolism is the sum
            total of all the chemical reactions in your body, plus the sum total
            of all your thoughts, feelings, beliefs, and experiences. You can
            reclaim your personal power and metabolic power. By choosing a
            healthy pleasure to relax while eating, you can achieve an increase
            in your metabolism.
          </p>
          <blockquote className="text-left font-semibold">
            There is more to life than increasing its speed.
          </blockquote>
          <cite className="font-semibold">Gandhi</cite>
          <p>
            When one eats under the state of stress or anxiety, the following
            symptoms will most likely be experienced: heartburn, cramping, gas,
            digestive pain, belching, and intense hunger. During stress on your
            body, it automatically shifts into classic fight-flight-freeze
            response.
          </p>
          <p>
            The moment your stress response is activated, your heart rate speeds
            up, blood pressure increases, respiration quikcens, hormones that
            provide immediate energy such as adrenaline, noradrenaline, and
            cortisol are released into your circulatory system, blood flow is
            rerouted away from your midsection and toward the head for quick
            thinking and to arms and legs for the necessary power to flee,
            freeze, or fly away. During this stress response, your digestive
            system completely shuts down. All your body’s metabolic function
            must be geared directly to survival.
          </p>
          <p>
            So, if you are eating in an anxious state, your food will just sit
            in your stomach waiting minutes to even hours for your body to kick
            back into its normal digestive functioning. You can eat the
            healthiest me, but if you eat in an anxious state, your digestion is
            dramatically diminished.
          </p>
          <p className="font-semibold">
            “Your mood affects the digestion of your food.”
          </p>
          <p>
            Your salivary enzyme content in your mouth is reduced, the breakdown
            of protein, fat, and carbohydrate macromolecules in the stomach is
            impaired, and the blood flow to your small intestines is decreased
            as much as fourfold. All of these changes in your body translates to
            decreased assimilation of vitamins, minerals, and other nutrients.
            So, it’s not only important what we eat; it is also the mental state
            we are in when we eat!
          </p>
          <p>
            Your (ANS) autonomic nervous system is the greatest portion of your
            CNS that influences your digestion. Your ANS is responsible for
            getting your stomach churning, getting your enzymatic secretions in
            the digestive process flowing, and keeping the nutrient absorption
            into your bloodstream on the move. There are two subdivisions of
            your ANS to accomplish this dual task of digestive arousal and
            inhibition. The (PNS) parasympathetic branch which relaxes your body
            to activate digestion, and the (SNS) sympathetic branch that
            activates your stress response and suppresses digestive activity.
          </p>
          <p className="font-semibold">
            “Take time to slow down in your life, and with food. Breathe deeply
            before, during, and after meals.”
          </p>
          <p>
            The simple act of taking time to slow down to eat can shift your SNS
            to your PNS dominance. Relaxation will create your metabolic power.
            The scientific documentation for the connection between weight gain
            and stress, shows conditions with high cortisol production is
            strongly associated with fat accumulation. One of the biggest
            responsibilities of cortisol is to signal the body to store fat and
            not build muscle. Cortisol is also the key hormone released in
            significant quantities during acute and chronic stress.
          </p>
          <p>
            Chronic stress can also increase the output of insulin, another
            hormone strongly associated with weight gain. One of the ways your
            insulin lowers your blood glucose is by telling the body to
            aggressively store excess dietary carbohydrates as fat. Insulin also
            signals to your body to not release any stored fat.
          </p>
          <p className="font-semibold">
            “Worrying about fat increases fat. Anxiety about weight loss causes
            your body to put on and retain it.”
          </p>
          <p className="font-semibold">
            “The way we do food is the way we do life. Slowing down with our
            meals, then is symbolic of relaxing into our body, our career, our
            fears and desires, and anything life presents.”
          </p>
          <p>
            The gut is often our barometer of our emotional states and
            stressors. These “gut feelings” are the gut brain, known as the
            (ENS) enteric nervous system. It is housed under and between layers
            of our esophagus, the stomach, and both intestines. The ENS is a
            network of neurons and neurochemicals that sense and control events
            in our digestive tract, along with other parts of the body, like our
            brain.
          </p>
          <p>
            Our gut-brain contains over one hundred million neurons-more than
            the number of nerve cells in our spinal cord. In fact, there is a
            significant greater flow of neural traffic from our ENS to the
            head-brain than from the head-brain to our ENS. So, rather than the
            head informing the digestive system what to eat and how much to
            metabolize, the locus of control is stationed in our belly.
          </p>
          <p>
            In addition to our extensive network of neurons, the entire
            digestive tract is also lined with cells that produce and receive a
            variety of neuropeptides and neurochemicals, as the same, in our
            brain: serotonin, dopamine, norepinephrine, and glutamate. And, many
            hormones once thought to be only in the gut, are also found in our
            brain: insulin, cholecystokinin, vasoactive intestinal protein,
            motilin, gastrin, somatostatin, thyrotropin releasing hormone,
            neurotensin, secretin, substance P, glucagon, and bombesin.
          </p>
          <p>
            Our ENS gut-brain and CNS also share a similar sleep state. Both
            move through cycles of 90 min slow-wave sleep frequencies, followed
            by REM. Also, they share 90 min of slow-wave muscular contractions
            followed by brief spurts of rapid muscular movements.
          </p>
          <p>
            The entire digestive tract is lined with specialized cells that
            produce and receive endorphins and enkephalins, chemicals that yield
            an array of sensations including joy, satisfaction, and pain relief.
            WE have a tremendous amount of brain power in our belly. Allow your
            head-brain to play it’s supportive role.
          </p>
          <p>
            With this wisdom, may you choose to slow down, breathe, and allow
            both your brain and belly flow with ease!
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default GutHealth
